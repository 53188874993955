import request from '@/utils/request'
import { ExamStudent } from "@/tool/_class"

export const importExamStudent = (data: {
  examProjectId: string,
  examSchoolId: string,
  examRoomIds: string[],
  items: {
    examAccount: string,
    studentName: string,
    sex: string,
    schoolName: string,
    className: string,
    photoFileName: string
  }[]
}) =>
  request({
    // 导入考生
    url: '/v1/ImportExamStudent',
    method: 'POST',
    data
  })

export const getExamStudentList = (data: {
  pageSize: number,
  curPage: number,
  examStudentId?: string,
  schoolId?: string,
  examSchoolId?: string,
  examProjectId?: string,
  keyWords?: string,
  examType?: string
}) =>
  request({
    // 获取考生列表
    url: '/v1/examStudentList',
    method: 'GET',
    params: data
  })


// export const queryExamPaper = (examPaperId: string) =>
//   request({
//     //  获取试卷by examPaperId
//     url: `/v1/examPaper/${examPaperId}`,
//     method: 'GET'
//   })

/**
 * 获取考生
 * @param examStudentId 
 * @returns 
 */
export const getExamStudent = (examStudentId: string) =>
  request({
    url: `/v1/examStudent/${examStudentId}`,
    method: 'get'
  })

export const deleteExamStudent = (examStudentId: string) =>
  request({
    // 删除考生by examStudentId
    url: `/v1/examStudent/${examStudentId}`,
    method: 'DELETE'
  })

export const postExamStudent = (data: ExamStudent, disabledTips: boolean = false) => request({
  url: '/v1/examStudent',
  method: 'post',
  headers: {
    "Content-Type": "application/json",
  },
  data,

  params: {
    disabledTips: disabledTips
  }

});



export const deleteProjectExamStudent = (data: { examProjectId: string, examSchoolId: string }) =>
  request({
    // 删除已经添加项目的考生
    url: '/v1/DeleteExamStudent',
    method: 'DELETE',
    params: data
  })

// export const uploadExamPapers = (data: any) =>
//   request({
//     // 上传试卷
//     url: '/v1/upload/examPapers',
//     method: 'POST',
//     headers: {
//       'Content-Type': 'multipart/form-data'
//     },
//     data
//   })
